import lodashRound from 'lodash/round';
export function calcCostTableRowTotal(row, numDecimals) {
    if (row) {
        const unitPrice = lodashRound(row.unitPrice, numDecimals);
        return lodashRound(unitPrice * row.amount, numDecimals);
    }
    return undefined;
}
;
export function calcCostTableTotal(costTable, numDecimals) {
    let sum = 0;
    if (costTable === null || costTable === void 0 ? void 0 : costTable.rows) {
        for (const row of costTable.rows) {
            sum += calcCostTableRowTotal(row, numDecimals);
        }
    }
    return lodashRound(sum, numDecimals);
}
