import angular from 'angular';
import * as PersonRoles from './person-roles';
import PersonHelper from './person-helper';
export const TkrPersonService = angular.module('tkrPersonService', []).factory('TkrPersonService', function () {
    var result = {};
    result.PJ_ROLE_VALUE = PersonRoles.PJ_ROLE_VALUE;
    result.VPJ_ROLE_VALUE = PersonRoles.VPJ_ROLE_VALUE;
    result.HK_ROLE_VALUE = PersonRoles.HK_ROLE_VALUE;
    result.TM_ROLE_VALUE = PersonRoles.TM_ROLE_VALUE;
    result.MUU_ROLE_VALUE = PersonRoles.MUU_ROLE_VALUE;
    result.SIHTEERI_ROLE_VALUE = PersonRoles.SIHTEERI_ROLE_VALUE;
    result.RH_ROLE_VALUE = PersonRoles.RH_ROLE_VALUE;
    result.TI_ROLE_VALUE = PersonRoles.TI_ROLE_VALUE;
    result.personRoles = PersonRoles.ALL_ROLES;
    result.chairmanRoles = PersonRoles.CHAIRMAN_ROLES;
    result.isValidChairmanRole = function (role) {
        return PersonHelper.isValidChairmanRole(role);
    };
    result.hasChairmanRole = function (person) {
        return PersonHelper.hasChairmanRole(person);
    };
    result.getPersonWithRole = function (persons, roleToSearch, valueIfNotFound) {
        return PersonHelper.getPersonWithRole(persons, roleToSearch, valueIfNotFound);
    };
    result.formatPerson = function (person, includeEmail) {
        if (!person) {
            return '(henkilö ei määritelty)';
        }
        var personStr = person.lastName + ' ' + person.firstName + ', ' +
            person.streetAddress + ', ' + person.zipCode + ' ' + person.city;
        if (includeEmail) {
            personStr += ', ' + person.emailAddress;
        }
        return personStr;
    };
    return result;
})
    .name;
const tkr_helpers_module = angular.module('tkr.helpers', []);
export const TkrHelpers = tkr_helpers_module.name;
tkr_helpers_module.factory('SchemaHelper', function () {
    var result = {};
    result.buildPropertyNameList = function (schema, path) {
        var propNames = [];
        var processObjProperty = function myself(path, obj) {
            for (var p in obj) {
                if (obj[p].type == 'object') {
                    myself((path ? '.' : '') + p, obj[p].properties);
                }
                else {
                    propNames.push((path ? path + '.' : '') + p);
                }
            }
        };
        if (schema && schema.hasOwnProperty('type')) {
            if (schema.type === 'object' && schema.hasOwnProperty('properties')) {
                propNames.concat(processObjProperty('', schema.properties));
            }
            else {
            }
        }
        return propNames;
    };
    return result;
});
