import angular from 'angular';
import ngSanitize from 'angular-sanitize';
import prcPortalResources from '../pp/prcportal-resource-modules';
import lang_select from '../common/tkr-lang-select-component';
import TkrAppConfig from '../common/TkrAppConfig';
export default angular.module('ng-tkr-auth', [
    TkrAppConfig,
    prcPortalResources,
    lang_select,
    ngSanitize
])
    .controller('LoginController', LoginController)
    .controller('PasswordChangeController', PasswordChangeController)
    .controller('MfaVerifyController', MfaVerifyController)
    .name;
PasswordChangeController.$inject = ['$scope', '$http'];
function PasswordChangeController($scope, $http) {
    $scope.pw1 = '';
    $scope.pw2 = '';
    $scope.pwChangeFormVisible = true;
    $scope.pwChangeErrorCode = 0;
    $scope.changePasswordClick = function () {
        var config = {};
        config.params = {};
        config.params.token = $scope.tokenId;
        config.params.password = $scope.pw1;
        $http.post('passwordchange', null, config).then(function (response) {
            $scope.pwChangeOk = true;
            $scope.pwChangeFormVisible = false;
        }, function (response) {
            $scope.pwChangeErrorCode = response.status;
        });
    };
    $scope.isPwSubmitAllowed = function () {
        if ($scope.pw1 == $scope.pw2) {
            return $scope.pw1.length > 0;
        }
        return false;
    };
}
const buildRedirectableFormActionValue = (appPath, $location, TkrConfig) => {
    const locationUrl = $location.url();
    const urlSuffix = locationUrl ? '#!'.concat(locationUrl) : '';
    const rawLoginUrl = TkrConfig.aUrl(`${appPath}${urlSuffix}`);
    return rawLoginUrl;
};
LoginController.$inject = ['PortalPostService', 'TkrConfig', '$sce', '$location'];
function LoginController(PortalPostService, TkrConfig, $sce, $location) {
    var ctrl = this;
    const rawSubmitUrl = buildRedirectableFormActionValue('login', $location, TkrConfig);
    ctrl.submitUrl = $sce.trustAsResourceUrl(rawSubmitUrl);
    PortalPostService.query(function (data) {
        if (data.length > 0) {
            ctrl.posts = data;
        }
    });
}
MfaVerifyController.$inject = ['TkrConfig', '$sce', '$location'];
function MfaVerifyController(TkrConfig, $sce, $location) {
    const ctrl = this;
    const rawSubmitUrl = buildRedirectableFormActionValue('login/mfa-verify', $location, TkrConfig);
    ctrl.submitUrl = $sce.trustAsResourceUrl(rawSubmitUrl);
}
