configureTranslateProvider.$inject = ['$translateProvider', 'TkrConfig'];
export function configureTranslateProvider($translateProvider, TkrConfig) {
    $translateProvider.useStaticFilesLoader({
        prefix: TkrConfig.aUrl('tiet/messages/'),
        suffix: ''
    });
    $translateProvider
        .useSanitizeValueStrategy('escape')
        .preferredLanguage(TkrConfig.locale);
}
