import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import uiBootstrapCollapse from 'angular-ui-bootstrap/src/collapse';
import tkrFiLocale from './angular-locale_fi-tkr';
import httpErrorInterceptor from './http-error-interceptor';
import { DebugSupportModuleName } from './m-dbg';
export default angular.module('tkr.spa.common', [
    uiRouter,
    uiBootstrapCollapse,
    tkrFiLocale,
    httpErrorInterceptor,
    DebugSupportModuleName,
])
    .name;
