import lodashRemove from 'lodash/remove';
export default {
    bindings: {
        applicationId: '@',
        attachmentDefs: '=',
        attachmentsValidCallback: '&',
        childForm: '='
    },
    template: require('./app-attachments-typed.tpl.html'),
    controller: ['$scope', 'TkrConfig', 'AppAttachmentService', 'Upload', '$sce', AppAttachmentsCtrl],
    controllerAs: 'vm'
};
function AppAttachmentsCtrl($scope, TkrConfig, AppAttachmentService, Upload, $sce) {
    var vm = this;
    this.$onInit = function () {
        vm.useAjaxFileUpload = supportAjaxUploadWithProgress();
        vm.maxSize = 1024 * 1024 * 10;
        vm.uploadUrl = TkrConfig.aUrl('tiet/api/hakemukset/' + vm.applicationId + '/liitteet');
        vm.trustedHtml = (input) => {
            return $sce.trustAsHtml(input);
        };
        vm.upload = function (file, attachmentDef) {
            if (file) {
                attachmentDef.meta = attachmentDef.meta || {};
                attachmentDef.meta.currPct = 0;
                attachmentDef.meta.error = false;
                if (attachmentDef.meta.files && !attachmentDef.allowMultiple) {
                    attachmentDef.meta.files.forEach(function (oldFile) {
                        oldFile.$remove().then(function (resp) {
                            lodashRemove(attachmentDef.meta.files, oldFile);
                        });
                    });
                }
                attachmentDef.meta.upload = Upload.upload({
                    url: vm.uploadUrl,
                    data: {
                        file: file,
                        attachmentType: attachmentDef.type
                    }
                });
                attachmentDef.meta.upload.then(function (okResp) {
                    attachmentDef.meta.upload = undefined;
                    vm.updateAttachments();
                }, function (errResp) {
                    attachmentDef.meta.upload = undefined;
                    if (errResp.xhrStatus == 'abort') {
                        attachmentDef.meta.currPct = 0;
                    }
                    else {
                        attachmentDef.meta.error = true;
                    }
                }, function (progressEvt) {
                    attachmentDef.meta.currPct = parseInt((100.0 * progressEvt.loaded / progressEvt.total).toString());
                });
            }
        };
        $scope.$watchCollection(function () { return vm.attachmentDefs; }, function (newVal, oldVal) {
            vm.updateAttachments();
        });
        vm.cancelUpload = function (attachmentDef) {
            if (attachmentDef.meta.upload) {
                attachmentDef.meta.upload.abort();
            }
        };
        vm.deleteAttachment = function (attachment) {
            attachment.$remove(function (data) {
                vm.updateAttachments();
            });
        };
    };
    vm.attUrl = function (attachment) {
        return AppAttachmentService.tkr.getAttachmentUrl(attachment.id, attachment.fileName);
    };
    vm.updateAttachments = function () {
        vm.requiredAttachmentsPresent = true;
        AppAttachmentService.queryForApplication({ applicationId: vm.applicationId }, function (data) {
            vm.attachmentDefs.forEach(function (ad) {
                ad.meta = ad.meta || {};
                ad.meta.files = [];
                ad.meta.currPct = 0;
            });
            vm.attachmentDefs.forEach(function (ad) {
                data.forEach(function (att) {
                    if (ad.type == att.userMeta.attachmentType) {
                        ad.meta.files.push(att);
                    }
                });
                if (ad.required && !ad.meta.files.length) {
                    delete vm.requiredAttachmentsPresent;
                }
            });
            if (vm.attachmentsValidCallback) {
                vm.attachmentsValidCallback({ value: (vm.requiredAttachmentsPresent ? true : false) });
            }
        });
    };
}
function supportAjaxUploadWithProgress() {
    return supportFileAPI() && supportAjaxUploadProgressEvents() && supportFormData();
    function supportFileAPI() {
        var fi = document.createElement('INPUT');
        fi.type = 'file';
        return 'files' in fi;
    }
    function supportAjaxUploadProgressEvents() {
        var xhr = new XMLHttpRequest();
        return !!(xhr && ('upload' in xhr) && ('onprogress' in xhr.upload));
    }
    function supportFormData() {
        return !!window.FormData;
    }
}
