import { calcCostTableTotal, calcCostTableRowTotal } from '../../common/CostTable';
export const AppEditCostTableComponent = {
    bindings: {
        costsTable: '=ngModel',
        helpBottom: '@',
        helpTop: '@',
        currencyUnit: '@',
        currencyDecimals: '@',
    },
    require: {
        ngModelCtrl: "ngModel",
        form: '^form'
    },
    template: require('./app-edit-cost-table.html'),
    controller: ['$element', Controller],
    controllerAs: 'vm'
};
function Controller($element) {
    const vm = this;
    this.$onInit = function () {
        vm.decimals = parseInt(vm.currencyDecimals);
        if ((!vm.decimals && vm.decimals !== 0) || vm.decimals < 0) {
            console.log(`warn: invalid currencyDecimals in costTable: ${vm.currencyDecimals}, reverting to default of 2`);
            vm.decimals = 2;
        }
        vm.calcRowTotal = (row) => {
            return calcCostTableRowTotal(row, vm.decimals);
        };
        vm.calcTableTotal = () => {
            return calcCostTableTotal(vm.costsTable, vm.decimals);
        };
        vm.deleteRow = (row) => {
            const idx = vm.costsTable.rows.indexOf(row);
            if (idx > -1) {
                vm.costsTable.rows.splice(idx, 1);
            }
        };
    };
}
