import angular from 'angular';
import TkrAppConfig from './TkrAppConfig';
const dbgModule = angular.module('m.dbg', [
    TkrAppConfig,
])
    .factory('mDbg', mDbg);
export const DebugSupportModuleName = dbgModule.name;
mDbg.$inject = ['$timeout'];
function mDbg($timeout) {
    function countWatches() {
        var root = angular.element(document.getElementsByTagName('html'));
        var watchers = [];
        var f = function (element) {
            angular.forEach(['$scope', '$isolateScope'], function (scopeProperty) {
                if (element.data() && element.data().hasOwnProperty(scopeProperty)) {
                    angular.forEach(element.data()[scopeProperty].$$watchers, function (watcher) {
                        watchers.push(watcher);
                    });
                }
            });
            angular.forEach(element.children(), function (childElement) {
                f(angular.element(childElement));
            });
        };
        f(root);
        var watchersWithoutDuplicates = [];
        angular.forEach(watchers, function (item) {
            if (watchersWithoutDuplicates.indexOf(item) < 0) {
                watchersWithoutDuplicates.push(item);
            }
        });
        return watchersWithoutDuplicates.length;
    }
    function setup($transitions) {
        if (__DEBUG__) {
            console.log('Debug mode active due to __DEBUG__');
            $transitions.onSuccess({}, function () {
                $timeout(function () {
                    console.log(`Watch count: ${countWatches()}`);
                }, 2000);
            });
        }
    }
    return {
        countWatches: countWatches,
        setup: setup
    };
}
