import angular from 'angular';
import ngLocaleFi from 'angular-i18n/fi';
export default angular.module('ngLocale.tkr', [ngLocaleFi])
    .decorator('$locale', LocaleDecorator)
    .name;
LocaleDecorator.$inject = ['$delegate'];
function LocaleDecorator($delegate) {
    $delegate.DATETIME_FORMATS.ts = 'dd.MM.yyyy HH:mm:ss';
    $delegate.DATETIME_FORMATS.lyhyt = 'd.M.yyyy HH:mm';
    return $delegate;
}
