export default {
    bindings: {
        ngModel: '=',
        ngRequired: '=',
        bicField: '=',
        inputLabel: '@',
        mHelp: '@',
    },
    require: {
        ngModelCtrl: "ngModel",
        form: '^form'
    },
    template: ['$element', '$attrs', IbanTemplateBuilder],
    controller: ['$element', AppEditIbanCtrl],
    controllerAs: 'vm'
};
function AppEditIbanCtrl($element) {
    var vm = this;
    this.$onInit = function () {
        vm.isRequired = function () {
            return $element.find('input').attr('required') ? true : false;
        };
    };
}
function IbanTemplateBuilder($element, $attrs) {
    var inputId = ('fld' + $attrs.ngModel + Math.random()).replace(/\W+/g, '');
    var inputLabel = $attrs.inputLabel || inputId;
    var htmlInputType = 'text';
    var mSize = $attrs.mSize || '';
    var required = '';
    if ($attrs.required !== undefined) {
        required = ' required="required"';
    }
    if ($attrs.ngRequired) {
        required = required.concat(' ng-required="vm.ngRequired"');
    }
    var html = [];
    html.push('<div class="form-group" ng-class="{ \'has-error\' : vm.form.INPUTID.$invalid }">');
    html.push('<label for="INPUTID" class="control-label col-sm-4 col-md-3">{{::vm.inputLabel}}<span ng-if="vm.isRequired()"> *</span></label>');
    html.push('<div class="col-sm-6">');
    html.push('<div class="input-group">');
    html.push('<input type="' + htmlInputType + '" class="form-control M_SIZE" id="INPUTID" name="INPUTID" placeholder="{{::vm.inputLabel}}" ng-model="vm.ngModel" iban-bic-to="vm.bicField" valid-iban ' + required + ' />');
    html.push('<div class="input-group-addon"><span ng-if="!vm.form.INPUTID.$invalid">{{vm.bicField}}</span></div>');
    html.push('</div>');
    html.push('<div ng-messages="vm.form.INPUTID.$error" role="alert">');
    html.push('<span ng-message="required" class="help-block bg-danger" translate>pakollinen_tieto</span>');
    html.push('<span ng-message="validIban" class="help-block bg-danger" translate>iban_virheellinen</span>');
    html.push('</div>');
    html.push('<p ng-if="::vm.mHelp" class="help-block">{{::vm.mHelp}}</p>');
    html.push('</div>');
    html.push('</div>');
    var result = html.join("\n").replace(/INPUTID/g, inputId).replace(/M_SIZE/g, mSize);
    return result;
}
