import angular from 'angular';
export default angular.module('httpErrorInterceptor', []).factory('HttpErrorInterceptor', ['$q', function ($q) {
        return {
            'requestError': function (rejection) {
                console.log('httpErrorInterceptor: requestError');
                return $q.reject(rejection);
            },
            'responseError': function (rejection) {
                if (rejection.status == 401) {
                    window.alert('Kirjautuminen on vanhentunut tai et ole kirjautunut sisään. Ole hyvä ja kirjaudu uudelleen.');
                    window.location.href = "../";
                    return $q.reject(rejection);
                }
                if (rejection.status == 403) {
                    window.alert('Käyttöoikeudet eivät riitä toiminnon suorittamiseen.\n\nTarvittaessa ota yhteyttä helpdesk@matriset.fi.');
                    return $q.reject(rejection);
                }
                if (rejection.status == 404 && rejection.config.ignoreNotFound) {
                    return $q.reject(rejection);
                }
                if (rejection.config && rejection.config.noIntercept && rejection.config.noIntercept.indexOf(rejection.status) != -1) {
                    return $q.reject(rejection);
                }
                if (rejection.status === 0 || rejection.status === -1) {
                }
                else if (rejection.status) {
                    var message = rejection.config.method + ' ' + rejection.config.url + '\nERROR ' + rejection.status;
                    window.alert(message);
                }
                else {
                    window.alert('Response parse ERROR -> contact helpdesk@matriset.fi');
                }
                return $q.reject(rejection);
            }
        };
    }]).config(['$httpProvider', function ($httpProvider) {
        $httpProvider.interceptors.push('HttpErrorInterceptor');
    }])
    .name;
