import angular from 'angular';
import { SupportedLanguages } from './localization';
import tkr_resources from './commonresources';
export default angular.module('tkr.langselect', ['tkr.appconfig', tkr_resources])
    .component('tkrLangSelect', {
    bindings: {
        style: '@',
    },
    require: {},
    template: ['$element', '$attrs', LangSelectTemplateBuilder],
    controller: ['$element', '$window', 'TkrConfig', 'SessionDataService', LangSelectCtrl],
    controllerAs: 'vm'
})
    .name;
function LangSelectCtrl($element, $window, TkrConfig, SessionDataService) {
    var vm = this;
    vm.langCfg = {};
    vm.langCfg[SupportedLanguages.FI_FI] = {
        name: 'Suomi',
        select_lang: 'Valitse kieli'
    };
    vm.langCfg[SupportedLanguages.SV_FI] = {
        name: 'Svenska',
        select_lang: 'Välj språk'
    };
    this.$onInit = function () {
        vm.locales = [];
        for (const locale of TkrConfig.availableLocales) {
            vm.locales.push({
                key: locale,
                name: vm.langCfg[locale].name || locale
            });
        }
        vm.locale = TkrConfig.locale;
        vm.sel = function (newLocale) {
            SessionDataService.get({ locale: newLocale.key }).$promise.then(function (data) {
                $window.location.reload(true);
            });
        };
    };
}
function LangSelectTemplateBuilder($element, $attrs) {
    const result = '<div style="{{::vm.style}}" ng-if="vm.locales.length > 1">' +
        '<p>{{vm.langCfg[vm.locale].select_lang || \'Select language\'}}' +
        '<a ng-repeat="locale in vm.locales" href="" ng-click="currLang || vm.sel(locale)" ng-init="currLang = vm.locale===locale.key" style="margin-left: 5px" class="btn btn-default" ng-class="{\'active\' : currLang}" ng-disabled="currLang">{{::locale.name}}</a></p>' +
        '</div>';
    return result;
}
