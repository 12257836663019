import lodashGet from 'lodash/get';
const OPERATORS = ['+', '-', '*', '/'];
export const tokenize = (formula) => {
    const result = [];
    let currentToken = '';
    for (let i = 0; i < formula.length; ++i) {
        if (OPERATORS.includes(formula[i])) {
            result.push(currentToken.trim());
            result.push(formula[i]);
            currentToken = '';
        }
        else {
            currentToken += formula[i];
        }
    }
    if (currentToken) {
        result.push(currentToken.trim());
    }
    return result;
};
export const evaluateFormula = (formula, context) => {
    if (formula && context) {
        const tokens = tokenize(formula);
        let expr = '';
        for (const token of tokens) {
            if (OPERATORS.includes(token)) {
                expr += token;
            }
            else {
                const value = lodashGet(context, token) || 0;
                expr += value;
            }
        }
        return eval(expr);
    }
    return undefined;
};
