export var SessionPermission;
(function (SessionPermission) {
    SessionPermission["SYSOP"] = "SYSOP";
    SessionPermission["PAPERAPP"] = "PAPERAPP";
})(SessionPermission || (SessionPermission = {}));
;
export var MgmtSettingsIds;
(function (MgmtSettingsIds) {
    MgmtSettingsIds["PrcEditorDefinition"] = "prc-editor-definition";
})(MgmtSettingsIds || (MgmtSettingsIds = {}));
;
export var DataType;
(function (DataType) {
    DataType["NUMBER"] = "number";
    DataType["STRING"] = "string";
    DataType["BOOL"] = "boolean";
    DataType["INTEGER"] = "integer";
    DataType["DATE"] = "date";
    DataType["ENUM"] = "enum";
    DataType["IBAN"] = "iban";
    DataType["PERSON_ROLE"] = "person_role";
    DataType["CHAIRMAN_ROLE"] = "chairman_role";
    DataType["ADDRESS_NAMES"] = "address_names";
    DataType["WEBSITE_ADDRESS"] = "website_address";
    DataType["FORMULA_NUMBER"] = "formula_number";
    DataType["OPTIONS_LIST"] = "options_list";
    DataType["COST_TABLE"] = "cost_table";
    DataType["EMAIL_ADDRESS"] = "email_address";
})(DataType || (DataType = {}));
;
export const DataTypeDescriptions = [
    {
        id: DataType.NUMBER,
        name: 'Desimaaliluku',
    },
    {
        id: DataType.STRING,
        name: 'Merkkijono',
    },
    {
        id: DataType.BOOL,
        name: 'Kyllä/ei',
    },
    {
        id: DataType.INTEGER,
        name: 'Kokonaisluku',
    },
    {
        id: DataType.DATE,
        name: 'Päivämäärä',
    },
    {
        id: DataType.IBAN,
        name: 'IBAN-tilinumero',
    },
    {
        id: DataType.PERSON_ROLE,
        name: 'Henkilön rooli',
    },
    {
        id: DataType.CHAIRMAN_ROLE,
        name: 'PJ/TM rooli',
    },
    {
        id: DataType.ADDRESS_NAMES,
        name: 'Osoitenimet',
    },
    {
        id: DataType.WEBSITE_ADDRESS,
        name: 'Verkkosivun osoite',
    },
    {
        id: DataType.OPTIONS_LIST,
        name: 'Valinta listasta',
    },
    {
        id: DataType.COST_TABLE,
        name: 'Kustannustaulukko hakemuksella',
    },
    {
        id: DataType.EMAIL_ADDRESS,
        name: 'Sähköpostiosoite',
    },
];
export const FormulaDataTypeDescriptions = [
    {
        id: DataType.FORMULA_NUMBER,
        name: 'KAAVA Desimaaliluku'
    },
];
export var ApplicationState;
(function (ApplicationState) {
    ApplicationState["SUBMITTED"] = "SUBMITTED";
    ApplicationState["DRAFT"] = "DRAFT";
})(ApplicationState || (ApplicationState = {}));
;
export var ApplicationSubmitType;
(function (ApplicationSubmitType) {
    ApplicationSubmitType[ApplicationSubmitType["ELECTRONIC"] = 0] = "ELECTRONIC";
    ApplicationSubmitType[ApplicationSubmitType["PAPER"] = 1] = "PAPER";
})(ApplicationSubmitType || (ApplicationSubmitType = {}));
;
export const FormulaDataTypes = [DataType.FORMULA_NUMBER];
