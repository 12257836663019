import * as PersonRoles from '../../common/person-roles';
export default {
    bindings: {
        ngModel: '=',
        ngRequired: '=',
        inputLabel: '@',
        mHelp: '@',
        personType: '@',
    },
    require: {
        ngModelCtrl: "ngModel",
        form: '^form'
    },
    template: ['$element', '$attrs', TemplateBuilder],
    controller: ['$element', Ctrl],
    controllerAs: 'vm'
};
function Ctrl($element) {
    var vm = this;
    this.$onInit = function () {
        vm.isRequired = function () {
            return $element.find('select').attr('required') ? true : false;
        };
        vm.roles = JSON.parse(JSON.stringify(vm.personType === 'chairman' ? PersonRoles.CHAIRMAN_ROLES : PersonRoles.ALL_ROLES));
        for (const role of vm.roles) {
            role.translationKey = `rooli_${role.value}`;
        }
    };
}
function TemplateBuilder($element, $attrs) {
    const inputId = ('fld' + $attrs.ngModel + Math.random()).replace(/\W+/g, '');
    const html = `<div class="form-group" ng-class="{ 'has-error' : vm.form.INPUTID.$invalid }">
  <label for="INPUTID" class="control-label col-sm-4 col-md-3">{{::vm.inputLabel}}<span ng-if="vm.isRequired()"> *</span></label>
  <div class="col-sm-6">
    <select class="form-control" id="INPUTID" name="INPUTID" ng-model="vm.ngModel" ng-options="option.value as option.translationKey | translate for option in vm.roles" ng-required="vm.ngRequired">
      <option value="">-- valitse --</option>
    </select>
    <div ng-messages="vm.form.INPUTID.$error">
      <span ng-message="required" class="help-block bg-danger">Pakollinen tieto</span>
    </div>
    <p ng-if="vm.mHelp" class="help-block">{{::vm.mHelp}}</p>
  </div>
</div>
`;
    const result = html.replace(/INPUTID/g, inputId);
    return result;
}
