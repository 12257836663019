export default {
    bindings: {
        ngModel: '=',
        inputLabel: '@',
        mHelp: '@',
    },
    require: {
        ngModelCtrl: "ngModel",
        form: '^form'
    },
    template: ['$element', '$attrs', TemplateBuilder],
    controller: ['$element', Ctrl],
    controllerAs: 'vm'
};
function Ctrl($element) {
    const vm = this;
    this.$onInit = function () {
    };
}
function TemplateBuilder($element, $attrs) {
    const inputId = ('fld' + $attrs.ngModel + Math.random()).replace(/\W+/g, '');
    const template = `<div class="form-group" ng-class="{ 'has-error' : vm.form.INPUTID.$invalid }">
          <label class="control-label col-md-3 col-sm-4" for="INPUTID" style="padding-top: 0px">{{::vm.inputLabel}} <span>*</span></label>
          <div class="col-sm-6">
            <div class="radio">
              <label>
                <input type="radio" name="INPUTID" ng-model="vm.ngModel" ng-value="true" style="padding-top: 0px" required /> {{::'yes' | translate}}
              </label>
            </div>
            <div class="radio">
              <label>
                <input type="radio" name="INPUTID" ng-model="vm.ngModel" ng-value="false" style="padding-top: 0px" required /> {{::'no' | translate}}
              </label>
            </div>
            <span class="help-block bg-danger" ng-show="vm.form.INPUTID.$error.required" translate>pakollinen_tieto</span>
            <span class="help-block">{{::vm.mHelp}}</span>
            </div>
        </div>`;
    const result = template.replace(/INPUTID/g, inputId);
    return result;
}
