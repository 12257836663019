import angular from 'angular';
import ngSanitize from 'angular-sanitize';
import uiBootstrapProgressBar from 'angular-ui-bootstrap/src/progressbar';
import tkrResources from '../../common/commonresources';
import maDatepicker from '../../common/ma-datepicker-uib';
import TkrAppConfig from '../../common/TkrAppConfig';
import AppRedirCtrl from './app-redir-controller';
import { AppSubmittedController } from './app-submitted-controller';
import SessionKeepAliveDirective from './session-keep-alive-directive';
import AppAttachmentsComponent from './app-attachments-component';
import AppAttachmentsTypedComponent from './app-attachments-typed-component';
import AppEditIbanComponent from './app-edit-iban-component';
import AppEditComponent from './app-edit-component';
import AppEditCbComponent from './app-edit-cb-component';
import AppEditDateComponent from './app-edit-date-component';
import AppEditBoolRadioComponent from './app-edit-bool-radio-component';
import AppEditAddressNamesComponent from './app-edit-address-names-component';
import AppEditRoleComponent from './app-edit-role-component';
import AppEditTextarea from './app-edit-textarea';
import AppEditWebsiteAddressComponent from './app-edit-website-address-component';
import AppEditOptionsMultiselectComponent from './app-edit-options-multiselect';
import AppEditOptionsRadiogroupComponent from './app-edit-options-radiogroup';
import { AppEditCostTableComponent } from './app-edit-cost-table';
import AppFormulaNumber from './app-formula-number-component';
import uiValidate from 'angular-ui-validate';
import ngFileUpload from 'ng-file-upload';
export default angular.module('tkr.pp.common', [
    ngSanitize,
    tkrResources,
    ngFileUpload,
    uiBootstrapProgressBar,
    maDatepicker,
    TkrAppConfig,
    uiValidate,
])
    .controller('AppRedirCtrl', AppRedirCtrl)
    .controller('AppSubmittedController', AppSubmittedController)
    .directive('sessionKeepAlive', SessionKeepAliveDirective)
    .component('appEditIban', AppEditIbanComponent)
    .component('appEdit', AppEditComponent)
    .component('appEditCb', AppEditCbComponent)
    .component('appEditDate', AppEditDateComponent)
    .component('appEditBoolRadio', AppEditBoolRadioComponent)
    .component('appEditAddressNames', AppEditAddressNamesComponent)
    .component('appEditRole', AppEditRoleComponent)
    .component('appAttachments', AppAttachmentsComponent)
    .component('appAttachmentsTyped', AppAttachmentsTypedComponent)
    .component('appEditTextarea', AppEditTextarea)
    .component('appFormulaNumber', AppFormulaNumber)
    .component('appEditWebsiteAddress', AppEditWebsiteAddressComponent)
    .component('appEditOptionsMultiselect', AppEditOptionsMultiselectComponent)
    .component('appEditOptionsRadiogroup', AppEditOptionsRadiogroupComponent)
    .component('appEditCostTable', AppEditCostTableComponent)
    .name;
