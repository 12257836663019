export const PJ_ROLE_VALUE = 'PJ';
export const VPJ_ROLE_VALUE = 'VPJ';
export const HK_ROLE_VALUE = 'HK';
export const TM_ROLE_VALUE = 'TM';
export const MUU_ROLE_VALUE = 'MUU';
export const SIHTEERI_ROLE_VALUE = 'S';
export const RH_ROLE_VALUE = 'RH';
export const TI_ROLE_VALUE = 'TI';
export const ALL_ROLES = Object.freeze([
    { name: 'Puheenjohtaja', value: PJ_ROLE_VALUE },
    { name: 'Varapuheenjohtaja', value: VPJ_ROLE_VALUE },
    { name: 'Sihteeri', value: SIHTEERI_ROLE_VALUE },
    { name: 'Rahastonhoitaja', value: RH_ROLE_VALUE },
    { name: 'Muu', value: MUU_ROLE_VALUE }
]);
export const CHAIRMAN_ROLES = Object.freeze([
    { name: 'Puheenjohtaja', value: PJ_ROLE_VALUE },
    { name: 'Toimitsijamies', value: TM_ROLE_VALUE }
]);
