import lodashRound from 'lodash/round';
import { evaluateFormula } from '../../common/formula-logic';
export default {
    bindings: {
        obj: '=',
        ngModel: '=',
        mHelp: '@',
        formula: '@',
        title: '@',
        decimals: '@',
        unit: '@',
    },
    require: {},
    template: ['$element', '$attrs', TemplateBuilder],
    controller: [Ctrl]
};
function Ctrl() {
    const $ctrl = this;
    this.$onInit = function () {
    };
    this.$doCheck = () => {
        $ctrl.ngModel = $ctrl.evaluateValue();
    };
    $ctrl.evaluateValue = () => {
        const rawValue = evaluateFormula($ctrl.formula, $ctrl.obj);
        let result = rawValue;
        if ($ctrl.decimals !== undefined) {
            result = lodashRound(result, $ctrl.decimals || 0);
        }
        return result;
    };
}
function TemplateBuilder() {
    return `
<div class="form-group">
  <label class="control-label col-sm-4 col-md-3">{{::$ctrl.title}}</label>
  <div class="col-sm-8">
    <div class="input-group short-group">
      <input class="form-control" ng-model="$ctrl.ngModel" readonly="true" />
      <span class="input-group-addon">{{::$ctrl.unit}}</span>
    </div>
    <p ng-if="$ctrl.mHelp" class="help-block">{{::$ctrl.mHelp}}</p>
  </div>
</div>`;
}
