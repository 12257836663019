import angular from 'angular';
SessionKeepAliveDirective.$inject = ['$interval', 'SessionDataService'];
export default function SessionKeepAliveDirective($interval, SessionDataService) {
    function link(scope, element, attrs) {
        var keepAliveMinutes = scope.sessionKeepAlive ? parseInt(scope.sessionKeepAlive) : 30;
        var lastActivity = Date.now();
        function onActivity(evt) {
            lastActivity = Date.now();
        }
        angular.forEach(['keydown', 'keyup', 'click', 'mousemove', 'DOMMouseScroll', 'mousewheel', 'mousedown', 'touchstart', 'touchmove', 'scroll', 'focus'], function (evtName) {
            element.on(evtName, function (e) { onActivity(e); });
        });
        var timerStop = $interval(function () {
            var currMs = Date.now();
            var secondsSinceLastActivity = (currMs - lastActivity) / 1000;
            var maxInactiveSeconds = keepAliveMinutes * 60;
            if (secondsSinceLastActivity < maxInactiveSeconds) {
                SessionDataService.get().$promise.then(function (data) {
                });
            }
        }, 1000 * 60);
        element.on('$destroy', function () {
            $interval.cancel(timerStop);
        });
    }
    return {
        restrict: 'A',
        scope: {
            sessionKeepAlive: '@'
        },
        link: link
    };
}
