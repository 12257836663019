export default {
    bindings: {
        ngModel: '=',
        ngRequired: '=',
        mReadOnly: '=',
        mHelp: '@',
        inputLabel: '@',
        inputAddon: '@',
    },
    require: {
        ngModelCtrl: "ngModel",
        form: '^form'
    },
    template: ['$element', '$attrs', TemplateBuilder],
    controller: ['$element', AppEditCtrl],
    controllerAs: 'vm'
};
function AppEditCtrl($element) {
    var vm = this;
    this.$onInit = function () {
        vm.isRequired = function () {
            return $element.find('input').attr('required') ? true : false;
        };
    };
}
function TemplateBuilder($element, $attrs) {
    var inputId = ('fld' + $attrs.ngModel + Math.random()).replace(/\W+/g, '');
    var htmlInputType = 'text';
    var inputExtra = '';
    var addonOpen = '';
    var addonClose = '';
    var mSize = $attrs.mSize || '';
    var short = $attrs.mShort !== undefined ? true : false;
    if ($attrs.mType == 'integer') {
        inputExtra += 'smart-integer';
    }
    else if ($attrs.mType == 'number') {
        inputExtra += 'smart-float';
    }
    else if ($attrs.mType == 'email') {
        htmlInputType = 'email';
    }
    if (!short) {
        inputExtra += ' placeholder="{{::vm.inputLabel}}"';
    }
    if ($attrs.inputAddon) {
        addonOpen = '<div class="input-group' + (short ? ' short-group' : '') + '">';
        addonClose = '<span class="input-group-addon">{{::vm.inputAddon}}</span></div>';
    }
    var required = '';
    if ($attrs.required !== undefined) {
        required = ' required="required"';
    }
    if ($attrs.ngRequired) {
        required = required.concat(' ng-required="vm.ngRequired"');
    }
    var html = [];
    html.push('<div class="form-group" ng-class="{ \'has-error\' : vm.form.INPUTID.$invalid }">');
    html.push('<label for="INPUTID" class="control-label col-sm-4 col-md-3">{{::vm.inputLabel}}<span ng-if="vm.isRequired()"> *</span></label>');
    html.push('<div class="col-sm-6">');
    html.push(addonOpen);
    html.push('<input type="' + htmlInputType + '" class="form-control M_SIZE" id="INPUTID" name="INPUTID" ng-disabled="vm.mReadOnly" ng-model="vm.ngModel" ' + inputExtra + required + ' />');
    html.push(addonClose);
    html.push('<div ng-messages="vm.form.INPUTID.$error" role="alert">');
    html.push('<p ng-message="required" class="help-block bg-danger" translate>pakollinen_tieto</p>');
    html.push('<p ng-message="integer" class="help-block bg-danger" translate>virheellinen_kokonaisluku</p>');
    html.push('<p ng-message="float" class="help-block bg-danger" translate>virheellinen_desimaaliluku</p>');
    html.push('<p ng-message="email" class="help-block bg-danger" translate>virheellinen_email</p>');
    html.push('</div>');
    html.push('<p ng-if="vm.mHelp" class="help-block">{{vm.mHelp}}</p>');
    html.push('<p ng-if="vm.mReadOnly" class="help-block" translate>vain_luku_hakemuskentta_ohje</p>');
    html.push('</div>');
    html.push('</div>');
    var result = html.join("\n").replace(/INPUTID/g, inputId).replace(/M_SIZE/g, mSize);
    return result;
}
