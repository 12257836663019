import angular from 'angular';
export const SmartFloat = angular.module('smartFloat', [])
    .directive('smartFloat', function () {
    return {
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {
            var validator = function (viewValue) {
                var FLOAT_REGEXP = /^\-?\d+((\.|\,)\d+)?$/;
                if (FLOAT_REGEXP.test(viewValue)) {
                    ctrl.$setValidity('float', true);
                    return parseFloat(viewValue.replace(',', '.'));
                }
                else {
                    ctrl.$setValidity('float', (viewValue === '' || viewValue === undefined || viewValue === null));
                    return viewValue;
                }
            };
            ctrl.$parsers.unshift(validator);
            ctrl.$formatters.unshift(validator);
        }
    };
})
    .name;
export const SmartInteger = angular.module('smartInteger', [])
    .directive('smartInteger', function () {
    return {
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {
            var validator = function (viewValue) {
                var n = ~~Number(viewValue);
                if (String(n) === viewValue && n >= 0) {
                    ctrl.$setValidity('integer', true);
                    return parseInt(viewValue);
                }
                else {
                    ctrl.$setValidity('integer', (viewValue === '' || viewValue === undefined || viewValue === null));
                    return viewValue;
                }
            };
            ctrl.$parsers.unshift(validator);
            ctrl.$formatters.unshift(validator);
        }
    };
})
    .name;
