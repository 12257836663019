AppRedirCtrl.$inject = ['$location', '$state'];
export default function AppRedirCtrl($location, $state) {
    var id = $location.absUrl().split('/').pop();
    if (id && id.length == 24) {
        $state.go('^.lomake', { applicationId: id });
    }
    else {
        $state.go('^.err');
    }
}
;
