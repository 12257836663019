import angular from 'angular';
import ngResource from 'angular-resource';
import TkrAppConfig from '../common/TkrAppConfig';
const prcportal_res_mod = angular.module('tkr.prcportal.resources', [ngResource, TkrAppConfig]);
export default prcportal_res_mod.name;
prcportal_res_mod.factory('PortalAppPeriodService', ['$resource', 'TkrConfig', function ($resource, TkrConfig) {
        return $resource(TkrConfig.ctxUrl + 'tiet/api/avustushaut/:periodId', {
            periodId: '@id'
        }, {
            update: {
                method: 'PUT'
            }
        });
    }]);
prcportal_res_mod.factory('PortalSubsidyAppService', ['$resource', 'TkrConfig', function ($resource, TkrConfig) {
        return $resource(TkrConfig.ctxUrl + 'tiet/api/hakemukset/:appId', {
            appId: '@id'
        }, {
            update: {
                method: 'PUT'
            }
        });
    }]);
prcportal_res_mod.factory('PortalSurveyService', ['$resource', 'TkrConfig', function ($resource, TkrConfig) {
        return $resource(TkrConfig.ctxUrl + 'tiet/api/kyselyt/:surveyId', {
            surveyId: '@id'
        }, {
            update: {
                method: 'PUT'
            }
        });
    }]);
prcportal_res_mod.factory('PortalSurveyResponseService', ['$resource', 'TkrConfig', function ($resource, TkrConfig) {
        return $resource(TkrConfig.ctxUrl + 'tiet/api/kyselyt/vastaukset/:surveyResponseId', {
            surveyResponseId: '@id'
        }, {
            update: {
                method: 'PUT'
            }
        });
    }]);
prcportal_res_mod.factory('PortalPostService', ['$resource', 'TkrConfig', function ($resource, TkrConfig) {
        return $resource(TkrConfig.ctxUrl + 'posts');
    }]);
