export default {
    bindings: {
        ngModel: '=',
        ngRequired: '=',
        mHelp: '@',
        inputLabel: '@',
    },
    require: {
        ngModelCtrl: "ngModel",
        form: '^form'
    },
    template: ['$element', '$attrs', TemplateBuilder],
    controller: ['$element', Controller],
    controllerAs: 'vm'
};
function Controller($element) {
    var vm = this;
    this.$onInit = function () {
        vm.isRequired = function () {
            return $element.find('input').attr('required') ? true : false;
        };
        vm.isValidWebsiteAddress = (value) => {
            if (value && typeof value === 'string') {
                return value.includes('.') &&
                    !value.startsWith('.') &&
                    !value.endsWith('.');
            }
            return true;
        };
    };
}
function TemplateBuilder($element, $attrs) {
    const inputId = ('fld' + $attrs.ngModel + Math.random()).replace(/\W+/g, '');
    let required = '';
    if ($attrs.required !== undefined) {
        required = ' required="required"';
    }
    if ($attrs.ngRequired) {
        required = required.concat(' ng-required="vm.ngRequired"');
    }
    let html = [];
    html.push(`<div class="form-group" ng-class="{ \'has-error\' : vm.form.${inputId}.$invalid }">`);
    html.push(`<label for="${inputId}" class="control-label col-sm-4 col-md-3">{{::vm.inputLabel}}<span ng-if="vm.isRequired()"> *</span></label>`);
    html.push('<div class="col-sm-6">');
    html.push(`<input type="text" class="form-control" id="${inputId}" name="${inputId}" ng-model="vm.ngModel" ${required} ui-validate="{website_address : 'vm.isValidWebsiteAddress($value)' }" />`);
    html.push(`<div ng-messages="vm.form.${inputId}.$error" role="alert">`);
    html.push('<p ng-message="required" class="help-block bg-danger">Pakollinen tieto</p>');
    html.push('<p ng-message="website_address" class="help-block bg-danger">Syötä kotisivun osoite</p>');
    html.push('</div>');
    html.push('<p ng-if="vm.mHelp" class="help-block">{{vm.mHelp}}</p>');
    html.push('</div>');
    html.push('</div>');
    let result = html.join("\n");
    return result;
}
