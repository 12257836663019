import angular from 'angular';
import '../custom/customization-module';
import 'angular-resource';
import 'angular-messages';
import 'angular-sanitize';
import uiRouter from '@uirouter/angularjs';
import { config } from './pp-app';
import './generic-app';
import '../auth/auth-app';
import TkrLangSelect from '../common/tkr-lang-select-component';
angular.module('tkr.pp', [
    uiRouter,
    'tkr.appconfig',
    TkrLangSelect,
])
    .config(config);
