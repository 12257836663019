export default {
    bindings: {
        ngModel: '=',
        required: '=',
        ngRequired: '=',
        maxlength: '=',
        rows: '=',
        mHelp: '@',
        inputLabel: '@',
    },
    require: {
        ngModelCtrl: "ngModel",
        form: '^form'
    },
    template: ['$element', '$attrs', TemplateBuilder],
    controller: ['$element', Ctrl],
    controllerAs: 'vm'
};
function Ctrl($element) {
    var vm = this;
    this.$onInit = function () {
        vm.isRequired = function () {
            return $element.find('textarea').attr('required') ? true : false;
        };
    };
}
function TemplateBuilder($element, $attrs) {
    var inputId = ('fld' + $attrs.ngModel + Math.random()).replace(/\W+/g, '');
    let required = '';
    if ($attrs.required !== undefined) {
        required = ' required="required"';
    }
    if ($attrs.ngRequired) {
        required = required.concat(' ng-required="vm.ngRequired"');
    }
    const html = `<div class="form-group" ng-class="{ \'has-error\' : vm.form.INPUTID.$invalid }">
  <div class="col-md-offset-3 col-md-9 col-sm-12">
    <h3>{{::vm.inputLabel}}</h3>
    <textarea class="form-control" id="INPUTID" name="INPUTID"
        ng-attr-rows="{{vm.rows}}"
        ng-model="vm.ngModel"
        ng-maxlength="vm.maxlength"
        ${required}></textarea>
    <div ng-messages="vm.form.INPUTID.$error" role="alert">
      <p ng-message="required" class="help-block bg-danger" translate>pakollinen_tieto</p>
      <p ng-message="maxlength" class="help-block bg-danger">{{::'teksti_liian_pitka' | translate}} (max {{vm.maxlength}}).</p>
    </div>
    <p ng-if="vm.mHelp" class="help-block">{{::vm.mHelp}}</p>
  </div>
</div>`;
    const result = html.replace(/INPUTID/g, inputId);
    return result;
}
