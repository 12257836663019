export default {
    bindings: {
        ngModel: '=',
        mReadOnly: '=',
        inputLabel: '@',
        mHelp: '@',
        defaultTown: '@',
    },
    require: {
        ngModelCtrl: "ngModel",
        form: '^form'
    },
    template: ['$element', '$attrs', TemplateBuilder],
    controller: ['$element', Ctrl],
};
function Ctrl($element) {
    const $ctrl = this;
    this.$onInit = function () {
        if (!Array.isArray($ctrl.ngModel)) {
            console.log(`app-edit-address-names: type of property to be edited is ${$ctrl.ngModel} insteadof an array`);
        }
        $ctrl.remove = (row) => {
            const index = $ctrl.ngModel.indexOf(row, 0);
            if (index > -1) {
                $ctrl.ngModel.splice(index, 1);
            }
        };
        $ctrl.add = () => {
            $ctrl.ngModel.push({});
        };
        $ctrl.isRequired = () => {
            return !!$element.attr('required');
        };
        $ctrl.updateValidity = () => {
            var _a;
            if ($ctrl.isRequired()) {
                $ctrl.form.$setValidity('addressNamesRequired', !!((_a = $ctrl.ngModel) === null || _a === void 0 ? void 0 : _a.length));
            }
        };
        $ctrl.$doCheck = () => {
            $ctrl.updateValidity();
        };
    };
}
function TemplateBuilder($element, $attrs) {
    const inputId = ('fld' + $attrs.ngModel + Math.random()).replace(/\W+/g, '');
    const template = `<div class="form-group">
  <label class="col-sm-3 control-label">{{$ctrl.inputLabel}} <span ng-if="$ctrl.isRequired()">*</span></label>
  <div class="col-sm-9">
    <table class="table table-condensed table-bordered table-hover m-table-content-vmiddle" style="margin-bottom: 5px;">
        <thead>
            <tr>
                <th translate>tien_nimi</th>
                <th><span translate>tien_kunta</span><span ng-if="$ctrl.defaultTown">, <span translate>jos_ei</span> {{::$ctrl.defaultTown}}</span></th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr ng-repeat="row in $ctrl.ngModel">
                <td ng-class="{ 'has-error' : $ctrl.form.{{::roadInputName}}.$invalid }" ng-init="roadInputName = 'INPUTID_road_' + $index">
                  <input ng-disabled="$ctrl.mReadOnly" ng-model="row.road" id="{{::roadInputName}}" name="{{::roadInputName}}" type="text" class="form-control" required />
                </td>
                <td ng-class="{ 'has-error' : $ctrl.form.{{::townInputName}}.$invalid }" ng-init="townInputName = 'INPUTID_town_' + $index">
                  <input ng-disabled="$ctrl.mReadOnly" ng-model="row.town" id="{{::townInputName}}" name="{{::townInputName}}" type="text" class="form-control" placeholder="{{::$ctrl.defaultTown}}" ng-required="!$ctrl.defaultTown" />
                </td>
                <td><a ng-if="!$ctrl.mReadOnly" href="" ng-click="$ctrl.remove(row)" translate>poista</a></td>
            </tr>
            <tr>
                <td colspan="3">
                <a ng-if="!$ctrl.mReadOnly" href="" ng-click="$ctrl.add()" translate>lisaa_osoitenimi</a>
            </td>
            </tr>
        </tbody>
    </table>
    <p class="help-block bg-danger" ng-if="$ctrl.form.$error.addressNamesRequired" translate>pakollinen_tieto</p>
    <p class="help-block" ng-if="$ctrl.mHelp">{{$ctrl.mHelp}}</p>
    <p ng-if="$ctrl.mReadOnly" class="help-block" translate>vain_luku_hakemuskentta_ohje</p>
  </div>
</div>`;
    const result = template.replace(/INPUTID/g, inputId);
    return result;
}
