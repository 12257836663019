export default {
    bindings: {
        ngModel: '=',
        ngRequired: '=',
        mHelp: '@',
        inputLabel: '@',
        optionsList: '=',
    },
    require: {
        ngModelCtrl: "ngModel",
        form: '^form'
    },
    template: ['$element', '$attrs', TemplateBuilder],
    controller: ['$element', Controller],
    controllerAs: 'vm'
};
function Controller($element) {
    var vm = this;
    this.$onInit = function () {
        vm.isRequired = function () {
            return $element.find('input').attr('required') ? true : false;
        };
    };
}
function TemplateBuilder($element, $attrs) {
    const inputIdBase = ('fld' + $attrs.ngModel + Math.random()).replace(/\W+/g, '');
    let required = '';
    if ($attrs.required !== undefined) {
        required = ' required="required"';
    }
    if ($attrs.ngRequired) {
        required = required.concat(' ng-required="vm.ngRequired"');
    }
    const result = `
<div class="form-group" ng-class="{ \'has-error\' : vm.form.${inputIdBase}.$invalid }">
  <label for="${inputIdBase}" class="control-label col-sm-4 col-md-3" style="padding-top: 0px">{{::vm.inputLabel}}<span ng-if="vm.isRequired()"> *</span></label>
  <div class="col-sm-8">
    <div class="radio" ng-repeat="option in vm.optionsList.options">
      <label>
        <input id="{{'${inputIdBase}_opt' + $index}}" name="${inputIdBase}" type="radio" ng-value="option.name" ng-model="vm.ngModel" ${required} /> {{::option.title || option.name}}
      </label>
    </div>
    <div ng-messages="vm.form.${inputIdBase}.$error" role="alert">
      <p ng-message="required" class="help-block bg-danger">Pakollinen tieto</p>
    </div>
    <p ng-if="vm.mHelp" class="help-block">{{vm.mHelp}}</p>
  </div>
</div>`;
    return result;
}
