export default {
    bindings: {
        ngModel: '=',
        ngRequired: '=',
        inputLabel: '@',
        mHelp: '@',
    },
    require: {
        ngModelCtrl: 'ngModel',
        form: '^form'
    },
    template: ['$element', '$attrs', AppEditDateTemplateBuilder],
    controller: ['$element', AppEditDateCtrl],
    controllerAs: 'vm'
};
function AppEditDateCtrl($element) {
    var vm = this;
    this.$onInit = function () {
        vm.isRequired = function () {
            return $element.find('input').attr('required') ? true : false;
        };
    };
}
function AppEditDateTemplateBuilder($element, $attrs) {
    var inputId = ('fld' + $attrs.ngModel + Math.random()).replace(/\W+/g, '');
    var inputLabel = $attrs.inputLabel || inputId;
    let required = '';
    if ($attrs.required !== undefined) {
        required = 'required="required"';
    }
    if ($attrs.ngRequired) {
        required = required.concat(' ng-required="vm.ngRequired"');
    }
    var html = [];
    html.push('<div class="form-group" ng-class="{ \'has-error\' : vm.form.INPUTID.$invalid }">');
    html.push('<label for="INPUTID" class="control-label col-sm-4 col-md-3">{{::vm.inputLabel}}<span ng-if="vm.isRequired()"> *</span></label>');
    html.push('<div class="col-sm-8">');
    html.push('<div class="short-group">');
    html.push(`<ma-datepicker id="INPUTID" name="INPUTID" ng-model="vm.ngModel" ${required.trim()}></ma-datepicker>`);
    html.push('</div>');
    html.push('<div ng-messages="vm.form.INPUTID.$error" role="alert">');
    html.push('<p ng-message="required" class="help-block bg-danger" translate>pakollinen_tieto</p>');
    html.push('<p ng-message="parse" class="help-block bg-danger" translate>virheellinen_pvm</p>');
    html.push('</div>');
    html.push('    <p class="help-block" ng-if="::vm.mHelp">{{::vm.mHelp}}</p>');
    html.push('</div>');
    html.push('</div>');
    var result = html.join("\n").replace(/INPUTID/g, inputId);
    return result;
}
