import angular from 'angular';
import ngResource from 'angular-resource';
import TkrAppConfig from './TkrAppConfig';
const res_mod = angular.module('tkr.resources', [
    ngResource,
    TkrAppConfig,
]);
export default res_mod.name;
res_mod.factory('AppAttachmentService', ['$resource', 'TkrConfig', function ($resource, TkrConfig) {
        var result = $resource(TkrConfig.aUrl('tiet/api/liitteet/:attachmentId'), {
            attachmentId: '@id'
        }, {
            queryForApplication: {
                method: 'GET',
                url: TkrConfig.aUrl('tiet/api/hakemukset/:applicationId/liitteet'),
                isArray: true
            }
        });
        result.tkr = {
            getAttachmentUrl: function (id, name) {
                return TkrConfig.aUrl('tiet/api/liitteet/' + id + '/' + encodeURIComponent(name));
            }
        };
        return result;
    }]);
res_mod.factory('SessionDataService', ['$resource', 'TkrConfig', function ($resource, TkrConfig) {
        return $resource(TkrConfig.aUrl('session'));
    }]);
