import { CHAIRMAN_ROLES } from './person-roles';
const trimToEmpty = (value) => {
    if (value) {
        return value.trim();
    }
    return '';
};
const equalsIgnoreCaseTrimmed = (a, b) => {
    return trimToEmpty(a).toLowerCase() === trimToEmpty(b).toLowerCase();
};
export default class PersonHelper {
    static isValidChairmanRole(role) {
        return !!CHAIRMAN_ROLES.find(elem => elem.value === role);
    }
    static hasChairmanRole(person) {
        return PersonHelper.isValidChairmanRole(person === null || person === void 0 ? void 0 : person.role);
    }
    static getPersonWithRole(persons, roleToSearch, valueIfNotFound = undefined) {
        if (persons && roleToSearch) {
            const rolesToSearch = (roleToSearch instanceof Array) ? roleToSearch : [roleToSearch];
            for (const candidate of persons) {
                if (rolesToSearch.find(elem => {
                    return (candidate === null || candidate === void 0 ? void 0 : candidate.role) === elem;
                })) {
                    return candidate;
                }
            }
        }
        return valueIfNotFound;
    }
    static stringifyPersonAddress(person) {
        let strs = [];
        if (person) {
            if (person.streetAddress) {
                strs.push(person.streetAddress.trim());
            }
            strs.push(`${(person === null || person === void 0 ? void 0 : person.zipCode) || ''} ${(person === null || person === void 0 ? void 0 : person.city) || ''}`.trim());
        }
        return strs.join(', ');
    }
    static stringifyRoleNameAddress(person) {
        let str = '';
        if (person) {
            str += person.role ? `[${person.role}] ` : '';
            str += (person.lastName || '');
            str += str ? ' ' : '';
            str += (person.firstName || '');
            str += str ? ', ' : '';
            str += PersonHelper.stringifyPersonAddress(person);
        }
        return str.trim();
    }
    static stringifyFull(person) {
        if (!person) {
            return '(henkilö ei määritelty)';
        }
        return `${person.role} ${person.lastName} ${person.firstName}, ` +
            `${person.streetAddress}, ${person.zipCode} ${person.city}, ` +
            `${person.phoneNumber}, ${person.emailAddress}`;
    }
    ;
    static hasValidAddress(person) {
        return ((person === null || person === void 0 ? void 0 : person.firstName) || (person === null || person === void 0 ? void 0 : person.lastName)) && (person === null || person === void 0 ? void 0 : person.streetAddress) && (person === null || person === void 0 ? void 0 : person.zipCode) && (person === null || person === void 0 ? void 0 : person.city);
    }
    static hasSameMailingAddress(a, b) {
        return equalsIgnoreCaseTrimmed(a === null || a === void 0 ? void 0 : a.firstName, b === null || b === void 0 ? void 0 : b.firstName) &&
            equalsIgnoreCaseTrimmed(a === null || a === void 0 ? void 0 : a.lastName, b === null || b === void 0 ? void 0 : b.lastName) &&
            equalsIgnoreCaseTrimmed(a === null || a === void 0 ? void 0 : a.streetAddress, b === null || b === void 0 ? void 0 : b.streetAddress) &&
            equalsIgnoreCaseTrimmed(a === null || a === void 0 ? void 0 : a.zipCode, b === null || b === void 0 ? void 0 : b.zipCode) &&
            equalsIgnoreCaseTrimmed(a === null || a === void 0 ? void 0 : a.city, b === null || b === void 0 ? void 0 : b.city);
    }
}
;
