import angular from 'angular';
export const IbanService = angular.module('ibanService', []).factory('IbanService', function () {
    var ibanService = {};
    ibanService.bankCodes = {
        HELSFIHH: [405, 497],
        BIGKFIH1: [717],
        POPFFI22: [470, 471, 472, 473, 474, 475, 476, 477, 478, 479],
        CITIFIHX: [713],
        DABAFIHH: [8, 34],
        DNBAFIHX: [37],
        HANDFIHH: [31],
        HOLVFIHH: [799],
        NDEAFIHH: [1, 2],
        OKOYFIHH: [5],
        ESSEFIHX: [33],
        SBANFIHH: [36, 39],
        SWEDFIHH: [38],
        ITELFIHH: [715, 400, 402, 403, 406, 407, 408,
            410, 411, 412,
            414, 415, 416, 417, 418, 419, 420, 421,
            423, 424, 425, 426, 427, 428, 429, 430, 431, 432,
            435, 436, 437, 438, 439, 440, 441, 442, 443, 444, 445, 446, 447, 448, 449, 450, 451, 452,
            454, 455, 456, 457, 458, 459, 460, 461, 462, 463, 464,
            483, 484, 485, 486, 487, 488, 489, 490, 491, 492, 493,
            495, 496],
        AABAFI22: [6]
    };
    ibanService.bicList = [];
    for (var bic in ibanService.bankCodes) {
        ibanService.bicList.push(bic);
    }
    ibanService.normalizeIban = function (iban) {
        if (!iban) {
            return undefined;
        }
        var result = iban;
        result = result.replace(/\s+/g, '');
        result = result.toUpperCase();
        return result;
    };
    ibanService.prettifyIban = function (iban) {
        var result = this.normalizeIban(iban);
        if (result) {
            if (result.length == 18) {
                return result.substr(0, 4) + ' ' +
                    result.substr(4, 4) + ' ' +
                    result.substr(8, 4) + ' ' +
                    result.substr(12, 4) + ' ' +
                    result.substr(16);
            }
        }
        return result;
    };
    ibanService.normalizeBic = function (bic) {
        if (!bic) {
            return undefined;
        }
        return bic.replace(/\s+/g, '').toUpperCase();
    };
    ibanService.getBicForIban = function (iban) {
        const nIban = this.normalizeIban(iban);
        if (!nIban) {
            return undefined;
        }
        if (nIban.length < 7) {
            return undefined;
        }
        let c = parseInt(nIban[4]);
        if (c == 3) {
            c = parseInt(nIban.substr(4, 2));
        }
        else if (c === 4 || c === 7) {
            c = parseInt(nIban.substr(4, 3));
        }
        else {
        }
        for (const prop in ibanService.bankCodes) {
            if (ibanService.bankCodes[prop].indexOf(c) != -1) {
                return prop;
            }
        }
        return undefined;
    };
    ibanService.validateIBAN = function (value) {
        if (!value) {
            return undefined;
        }
        var iban = value.replace(/ /g, "").toUpperCase(), ibancheckdigits = "", leadingZeroes = true, cRest = "", cOperator = "", countrycode, ibancheck, charAt, cChar, bbanpattern, bbancountrypatterns, ibanregexp, i, p;
        var minimalIBANlength = 5;
        if (iban.length < minimalIBANlength) {
            return false;
        }
        countrycode = iban.substring(0, 2);
        bbancountrypatterns = {
            "AL": "\\d{8}[\\dA-Z]{16}",
            "AD": "\\d{8}[\\dA-Z]{12}",
            "AT": "\\d{16}",
            "AZ": "[\\dA-Z]{4}\\d{20}",
            "BE": "\\d{12}",
            "BH": "[A-Z]{4}[\\dA-Z]{14}",
            "BA": "\\d{16}",
            "BR": "\\d{23}[A-Z][\\dA-Z]",
            "BG": "[A-Z]{4}\\d{6}[\\dA-Z]{8}",
            "CR": "\\d{17}",
            "HR": "\\d{17}",
            "CY": "\\d{8}[\\dA-Z]{16}",
            "CZ": "\\d{20}",
            "DK": "\\d{14}",
            "DO": "[A-Z]{4}\\d{20}",
            "EE": "\\d{16}",
            "FO": "\\d{14}",
            "FI": "\\d{14}",
            "FR": "\\d{10}[\\dA-Z]{11}\\d{2}",
            "GE": "[\\dA-Z]{2}\\d{16}",
            "DE": "\\d{18}",
            "GI": "[A-Z]{4}[\\dA-Z]{15}",
            "GR": "\\d{7}[\\dA-Z]{16}",
            "GL": "\\d{14}",
            "GT": "[\\dA-Z]{4}[\\dA-Z]{20}",
            "HU": "\\d{24}",
            "IS": "\\d{22}",
            "IE": "[\\dA-Z]{4}\\d{14}",
            "IL": "\\d{19}",
            "IT": "[A-Z]\\d{10}[\\dA-Z]{12}",
            "KZ": "\\d{3}[\\dA-Z]{13}",
            "KW": "[A-Z]{4}[\\dA-Z]{22}",
            "LV": "[A-Z]{4}[\\dA-Z]{13}",
            "LB": "\\d{4}[\\dA-Z]{20}",
            "LI": "\\d{5}[\\dA-Z]{12}",
            "LT": "\\d{16}",
            "LU": "\\d{3}[\\dA-Z]{13}",
            "MK": "\\d{3}[\\dA-Z]{10}\\d{2}",
            "MT": "[A-Z]{4}\\d{5}[\\dA-Z]{18}",
            "MR": "\\d{23}",
            "MU": "[A-Z]{4}\\d{19}[A-Z]{3}",
            "MC": "\\d{10}[\\dA-Z]{11}\\d{2}",
            "MD": "[\\dA-Z]{2}\\d{18}",
            "ME": "\\d{18}",
            "NL": "[A-Z]{4}\\d{10}",
            "NO": "\\d{11}",
            "PK": "[\\dA-Z]{4}\\d{16}",
            "PS": "[\\dA-Z]{4}\\d{21}",
            "PL": "\\d{24}",
            "PT": "\\d{21}",
            "RO": "[A-Z]{4}[\\dA-Z]{16}",
            "SM": "[A-Z]\\d{10}[\\dA-Z]{12}",
            "SA": "\\d{2}[\\dA-Z]{18}",
            "RS": "\\d{18}",
            "SK": "\\d{20}",
            "SI": "\\d{15}",
            "ES": "\\d{20}",
            "SE": "\\d{20}",
            "CH": "\\d{5}[\\dA-Z]{12}",
            "TN": "\\d{20}",
            "TR": "\\d{5}[\\dA-Z]{17}",
            "AE": "\\d{3}\\d{16}",
            "GB": "[A-Z]{4}\\d{14}",
            "VG": "[\\dA-Z]{4}\\d{16}"
        };
        bbanpattern = bbancountrypatterns[countrycode];
        if (typeof bbanpattern !== "undefined") {
            ibanregexp = new RegExp("^[A-Z]{2}\\d{2}" + bbanpattern + "$", "");
            if (!(ibanregexp.test(iban))) {
                return false;
            }
        }
        ibancheck = iban.substring(4, iban.length) + iban.substring(0, 4);
        for (i = 0; i < ibancheck.length; i++) {
            charAt = ibancheck.charAt(i);
            if (charAt !== "0") {
                leadingZeroes = false;
            }
            if (!leadingZeroes) {
                ibancheckdigits += "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ".indexOf(charAt);
            }
        }
        for (p = 0; p < ibancheckdigits.length; p++) {
            cChar = ibancheckdigits.charAt(p);
            cOperator = "" + cRest + "" + cChar;
            cRest = String(parseInt(cOperator) % 97);
        }
        return parseInt(cRest) === 1;
    };
    return ibanService;
})
    .name;
export const IbanValidator = angular.module('ibanValidator', [IbanService])
    .directive('validIban', ValidIbanDirective)
    .directive('ibanBicTo', IbanBicToDirective)
    .name;
ValidIbanDirective.$inject = ['IbanService'];
function ValidIbanDirective(IbanService) {
    return {
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {
            var validator = function (viewValue) {
                var ibanToValidate = IbanService.normalizeIban(viewValue);
                scope.ibanValid = IbanService.validateIBAN(ibanToValidate);
                if (scope.ibanValid) {
                    ctrl.$setValidity('validIban', true);
                    return viewValue;
                }
                else {
                    ctrl.$setValidity('validIban', (ibanToValidate === '' || ibanToValidate === undefined || ibanToValidate === null));
                    return viewValue;
                }
            };
            ctrl.$parsers.unshift(validator);
            ctrl.$formatters.unshift(validator);
        }
    };
}
IbanBicToDirective.$inject = ['IbanService'];
function IbanBicToDirective(IbanService) {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attrs, ngModel) {
            scope.$watch(function () {
                return ngModel.$modelValue;
            }, function (newValue) {
                scope.ibanBicTo = IbanService.getBicForIban(newValue);
            });
        },
        scope: {
            ibanBicTo: '='
        }
    };
}
