import angular from 'angular';
import uibDatepickerPopup from 'angular-ui-bootstrap/src/datepickerPopup';
export default angular.module('m.dp.uib', [
    uibDatepickerPopup,
])
    .component('maDatepicker', {
    bindings: {
        ngModel: '=',
        ngRequired: '=',
    },
    require: {
        ngModelCtrl: '^ngModel'
    },
    template: ['$attrs', TemplateBuilder],
    controller: ['$element', Controller],
    controllerAs: 'vm'
})
    .name;
function TemplateBuilder($attrs) {
    const inputId = $attrs.id || ('dp' + $attrs.ngModel + Math.random()).replace(/\W+/g, '');
    const inputName = $attrs.name || inputId;
    let igClass = 'input-group';
    if ($attrs.mSize == 'sm') {
        igClass += ' input-group-sm';
    }
    let required = '';
    if ($attrs.required !== undefined) {
        required = 'required="required"';
    }
    if ($attrs.ngRequired) {
        required = required.concat(' ng-required="vm.ngRequired"');
    }
    const html = [];
    html.push(`<div class="${igClass}">`);
    html.push(`<input type="text" class="form-control" uib-datepicker-popup="d.M.yyyy" alt-input-formats="[\'d!.M!.yyyy\']" ng-model="vm.dt" ng-model-options="{ getterSetter: true }" is-open="vm.isOpen" datepicker-options="vm.dateOptions" ${required.trim()} current-text="Tänään" clear-text="Tyhjennä" close-text="Sulje" id="${inputId}" name="${inputName}" />`);
    html.push('<span class="input-group-btn">');
    html.push('<button type="button" class="btn btn-default" ng-click="vm.isOpen=true"><i class="glyphicon glyphicon-calendar"></i></button>');
    html.push('</span>');
    html.push('</div>');
    return html.join('\n');
}
function Controller($element) {
    const vm = this;
    this.$onInit = function () {
        vm.isRequired = function () {
            return $element.find('input').attr('required') ? true : false;
        };
        vm.dateOptions = {
            dateFormat: 'd.m.yy'
        };
        vm.dt = function (val) {
            if (arguments.length) {
                vm.cached = val;
                if (val) {
                    var tzoffset = val.getTimezoneOffset() * 60000;
                    vm.ngModel = (new Date(val.getTime() - tzoffset)).toISOString().substr(0, 10);
                }
                else {
                    vm.ngModel = undefined;
                }
            }
            else {
                if (!vm.cached && vm.ngModel) {
                    vm.cached = new Date(vm.ngModel);
                }
                return vm.cached;
            }
        };
    };
}
