export default {
    bindings: {
        applicationId: '@',
        noAttachmentsHtml: '@'
    },
    template: require('./app-attachments.tpl.html'),
    controller: ['$scope', 'TkrConfig', 'AppAttachmentService', 'Upload', AppAttachmentsCtrl],
    controllerAs: 'vm'
};
function AppAttachmentsCtrl($scope, TkrConfig, AppAttachmentService, Upload) {
    var vm = this;
    this.$onInit = function () {
        vm.useAjaxFileUpload = supportAjaxUploadWithProgress();
        vm.maxSize = 1024 * 1024 * 10;
        vm.uploadUrl = TkrConfig.aUrl('tiet/api/hakemukset/' + vm.applicationId + '/liitteet');
        vm.updateAttachments();
        $scope.$watch(function () {
            return vm.files;
        }, function (newValue) {
            if (newValue) {
                vm.upload(Array.isArray(newValue) ? newValue : [newValue]);
            }
        });
        vm.upload = function (files) {
            vm.uploadAlerts = [];
            if (files && files.length) {
                for (var i = 0; i < files.length; i++) {
                    var file = files[i];
                    if (file.size > vm.maxSize) {
                        vm.uploadAlerts.push({ style: 'alert-danger', text: 'Tiedosto ' + file.name + ' on liian suuri. Maksimikoko on ' + vm.maxSize / 1024 / 1024 + ' megatavua.' });
                    }
                    else {
                        vm.currentPercentage = 0;
                        Upload.upload({
                            url: vm.uploadUrl,
                            file: file
                        }).progress(function (evt) {
                            vm.currentPercentage = parseInt((100.0 * evt.loaded / evt.total).toString());
                        }).success(function (data, status, headers, config) {
                            vm.uploadAlerts.push({ style: 'alert-success', text: 'Tiedosto ' + file.name + ' lähetetty onnistuneesti.' });
                            vm.updateAttachments();
                        });
                    }
                }
            }
        };
        vm.deleteAttachment = function (attachment) {
            attachment.$remove(function (data) {
                vm.updateAttachments();
            });
        };
        vm.descBlur = function (attachment) {
            if (vm.prevDesc[attachment.id] != attachment.userMeta.desc) {
                attachment.$save(function (data) {
                    vm.prevDesc[attachment.id] = data.userMeta.desc;
                });
            }
        };
    };
    vm.attUrl = function (attachment) {
        return AppAttachmentService.tkr.getAttachmentUrl(attachment.id, attachment.fileName);
    };
    vm.updateAttachments = function () {
        AppAttachmentService.queryForApplication({ applicationId: vm.applicationId }, function (data) {
            vm.prevDesc = {};
            data.forEach(function (elem) {
                vm.prevDesc[elem.id] = elem.userMeta.desc;
            });
            vm.attachments = data;
            vm.currentPercentage = undefined;
        });
    };
}
function supportAjaxUploadWithProgress() {
    return supportFileAPI() && supportAjaxUploadProgressEvents() && supportFormData();
    function supportFileAPI() {
        var fi = document.createElement('INPUT');
        fi.type = 'file';
        return 'files' in fi;
    }
    function supportAjaxUploadProgressEvents() {
        var xhr = new XMLHttpRequest();
        return !!(xhr && ('upload' in xhr) && ('onprogress' in xhr.upload));
    }
    function supportFormData() {
        return !!window.FormData;
    }
}
