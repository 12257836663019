AppSubmittedController.$inject = [
    '$window',
    '$stateParams',
    'PortalSurveyService',
    'PortalSurveyResponseService',
    'PortalSubsidyAppService',
    'PortalAppPeriodService',
    'TkrConfig'
];
export function AppSubmittedController($window, $stateParams, PortalSurveyService, PortalSurveyResponseService, PortalSubsidyAppService, PortalAppPeriodService, TkrConfig) {
    var vm = this;
    var appId = $stateParams.applicationId;
    if (!appId) {
        $window.alert('VIRHE hakemusta ei tallennettu --- FEL bidragsansökan sparades inte');
        $window.location.replace('/');
    }
    vm.getPdfUrl = function () {
        return TkrConfig.aUrl('tiet/hakemukset/' + appId + '/Avustushakemus.pdf');
    };
    vm.getHomeUrl = function () {
        return TkrConfig.aUrl('tiet');
    };
    PortalSurveyService.query({ applicationId: appId }).$promise.then(function (surveyData) {
        if (surveyData && surveyData.length) {
            PortalSurveyResponseService.query({ applicationId: appId }).$promise.then(function (respData) {
                if (respData && respData.length) {
                }
                else {
                    vm.surveyResponse = new PortalSurveyResponseService();
                    vm.surveyResponse.surveyId = surveyData[0].id;
                    vm.surveyResponse.metaData = {
                        applicationId: appId
                    };
                }
            });
        }
    });
    PortalSubsidyAppService.get({ appId: $stateParams.applicationId }).$promise.then(function (app) {
        vm.subsidyApplication = app;
        PortalAppPeriodService.get({ periodId: app.applicationPeriodId }).$promise.then(function (appPeriod) {
            vm.appPeriod = appPeriod;
        });
    });
    vm.submitSurvey = function () {
        vm.surveySubmitted = true;
        vm.surveyResponse.$save();
        vm.surveyResponse = null;
    };
    vm.closeSurvey = function () {
        vm.surveyResponse = null;
    };
}
